import React from "react";
import "./ShareButtons.scss";
import {
  EmailShare,
  FacebookMessengerShare,
  FacebookShare,
  RedditShare,
  TwitterShare,
  WhatsappShare,
} from "react-share-lite";

function ShareButtons() {
  const shareUrl = "https://rikospodcast.fi/";
  const title = "Rikospodcast-haku | Löydä kotimainen true crime";
  const size = "46px";
  const borderRadius = 10;

  return (
    <div className="share-buttons">
      <FacebookShare
        url={shareUrl}
        quote={title}
        size={size}
        borderRadius={borderRadius}
      />
      <WhatsappShare
        url={shareUrl}
        quote={title}
        size={size}
        borderRadius={borderRadius}
      />
      <TwitterShare
        url={shareUrl}
        quote={title}
        size={size}
        borderRadius={borderRadius}
      />
      <FacebookMessengerShare
        url={shareUrl}
        quote={title}
        size={size}
        borderRadius={borderRadius}
      />
      <EmailShare
        url={shareUrl}
        quote={title}
        size={size}
        borderRadius={borderRadius}
      />
      <RedditShare
        url={shareUrl}
        quote={title}
        size={size}
        borderRadius={borderRadius}
      />
    </div>
  );
}

export default ShareButtons;
